import React from "react";
import ChatButton from "../chatBtnAr";
import iconSaveSnafu from "../../../images/en/icon-save-snafu.png";

const SaveSnafuTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="save-snafu"
        role="tabpanel"
        aria-labelledby="save-snafu-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-save-snafu.png"
              alt="Save Snafu"
              quality={100}
            /> */}
            <img src={iconSaveSnafu} alt="Save Snafu" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Save Snafu</h3> */}
          <p>
            عملت ريستارت في الوقت الغلط وراحت أيام وأيام من اللعب والكدح على
            الزبالة؟ أو سلّمت اللعبة لأخوك الصغير؟ أو بكل بساطة كنت جوعان
            وتهوّرت بلا ما تسيّف؟ نحنا نغطيك. ولو لن نعوّض لك كل الوقت والتعب
            الضائع، سوف نراضيك بسنكرس.
          </p>
          <div className="text-center">
            <span className="text-uppercase link">
              <ChatButton innerText="تكلّم مع وكيل" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SaveSnafuTab;

import React from "react";
import ClaimButton from "./claimButtonAr";

const Claim = ({ onHide }) => {
  return (
    <>
      <section className="text-center modal-section">
        <ClaimButton onHide={onHide} />
      </section>
    </>
  );
};

export default Claim;

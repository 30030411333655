import React from "react";
import ChatButton from "../chatBtnAr";
import iconRageQuit from "../../../images/en/icon-rage-quit.png";

const RageQuitTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="rage-quit"
        role="tabpanel"
        aria-labelledby="rage-quit-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-rage-quit.png"
              alt="Rage Quit"
              quality={100}
            /> */}
            <img src={iconRageQuit} alt="Rage Quit" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Rage Quit</h3> */}
          <p>
            خبطت أي شيء أو أي أحد بعدما خبّصت باللعب وفقدت أعصابك؟ لا نغطّي أي
            ضرر حقيقي للأشخاص أو الأغراض، لكن يمكن أن نساعدك تتفادى حادثة ترييج
            وإغلاق بسبب الجوع في المستقبل، وممكن أن نقترح لك دروس في السيطرة على
            الأعصاب.
          </p>
          <div className="text-center">
            <span className="text-uppercase link">
              <ChatButton innerText="تكلّم مع وكيل" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default RageQuitTab;

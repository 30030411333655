import React from "react";

import iconComment from "../../images/icon-comment.png";
import profilePhoto from "../../images/profile-photo.png";

const ClaimButton = () => {
  const openChatHandler = () => {
    let chatBtn;
    if (document.querySelector("df-messenger-chat-bubble")) {
      chatBtn = document.querySelector("df-messenger-chat-bubble");
      chatBtn.shadowRoot.querySelector("button").click();
    }
  };
  return (
    <>
      <div
        className="btn btn-claim"
        id="btn-claim"
        onClick={openChatHandler}
        onKeyDown={openChatHandler}
        role="button"
        tabIndex={0}
      >
        <figure className="mb-0 icon-comment icon-comment-margin">
          {/* <StaticImage
            src="../../images/icon-comment.png"
            alt="Icon Comment"
            quality={100}
          /> */}
          <img src={iconComment} alt="Icon Comment" className="img-fluid" />
        </figure>
        تقدّم بطلب
        <figure className="mb-0 profile-photo">
          {/* <StaticImage
            src="../../images/profile-photo.png"
            alt="Profile Photo"
            quality={100}
          /> */}
          <img src={profilePhoto} alt="Icon Profile" className="img-fluid" />
        </figure>
      </div>
    </>
  );
};

export default ClaimButton;

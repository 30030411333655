import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const HomeContent = () => {
  return (
    <>
      <div className="content text-white">
        <div>
          <figure class="mb-3 mb-lg-4">
            <StaticImage
              src="../../images/hero-banner.jpg"
              alt="Hero Banner"
              quality={100}
            />
          </figure>
        </div>
        <div className="page-heading">
          <h1 className="text-uppercase">تخبيصك في اللعب يكسبّك سنكرس.</h1>
        </div>
        <p className="text-md mb-0">
          لو الجوع دفعك للتخبيص في الألعاب، سنكرس يغطّيك مع تأمين سنكرس ضدّ
          الجوع المبتكر تقدر تحوّل تخبيصك في اللعب لسنكرس.
        </p>
        <p className="text-md mb-0">
          اختار البوليصة إلّي تناسبك أو تواصل مع وكلائنا واعرف إذا تخبيصك قابل
          للتغطية، وكلاؤنا مستعدون لمساعدتك.
        </p>
      </div>
    </>
  );
};

export default HomeContent;

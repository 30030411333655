import React from "react";
import ChatButton from "../chatBtnAr";
import iconEarlyDeath from "../../../images/en/icon-early-death.png";

const EarlyDeathTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="early-death"
        role="tabpanel"
        aria-labelledby="early-death-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-early-death.png"
              alt="Early Death"
              quality={100}
            /> */}
            <img src={iconEarlyDeath} alt="Early Death" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Early Death</h3> */}
          <p>
            كنت أوّل الخسرانين بين 100 لاعب في الباتل رويال؟ قتلك أوّل وأسهل وحش
            قبل ما تاخذ نفس حتى؟ متت وتركت فريقك قبل ما تبدى المعركة؟ نحنا نغطيك
            مهما كانت طريقة موتك السريع سخيفة. حوّل تخبيصاتك المحرجة لسنكرس!
          </p>
          <div className="text-center">
            <span className="text-uppercase link">
              <ChatButton innerText="تكلّم مع وكيل" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EarlyDeathTab;

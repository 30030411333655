import React from "react";
import ChatButton from "../chatBtnAr";
import iconNoobMove from "../../../images/en/icon-noob-move.png";

const NoobMoveTab = () => {
  return (
    <>
      <div
        className="tab-pane fade show active"
        id="noob-move"
        role="tabpanel"
        aria-labelledby="noob-move-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-noob-move.png"
              alt="Noob Move"
              quality={100}
            /> */}
            <img src={iconNoobMove} alt="Noob Move" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Noob Move</h3> */}
          <p>
            خبّصت تخبيصة مثل راسك لدرجة أكثر النوبز يحسّ بالإحراج عنّك؟ نحنا
            نغطيك. وكلاؤنا مستعدون لمساعدتك لتقديم طلبك من دون أي إحراج.
          </p>
          <div className="text-center">
            <span className="text-uppercase link ">
              <ChatButton innerText="تكلّم مع وكيل" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoobMoveTab;

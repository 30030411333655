import React from "react";
import ChatButton from "../chatBtnAr";
import iconLootLoss from "../../../images/en/icon-loot-loss.png";

const LootLossTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="loot-loss"
        role="tabpanel"
        aria-labelledby="loot-loss-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-loot-loss.png"
              alt="Loot Loss"
              quality={100}
            /> */}
            <img src={iconLootLoss} alt="Loot Loss" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Loot Loss</h3> */}
          <p>
            وأخيراً بعد جهد جهيد لقيت لوت أسطوري وضيّعته بعد لحظات لأنّك طحت من
            الحافّة وإنت بتحتفل بحظّك الحلو؟ نقدّملك تعويض حقيقي على الخسارة
            الوهمية إلّي بليت نفسك بها بسبب جوعك.
          </p>
          <div className="text-center">
            <span className="text-uppercase link">
              <ChatButton innerText="تكلّم مع وكيل" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LootLossTab;

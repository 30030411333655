import React from "react";

import TabLinks from "./tabLinksAr";
import NoobMoveTab from "./tab-content-ar/noobMoveTab";
import EarlyDeathTab from "./tab-content-ar/earlyDeathTab";
import RageQuitTab from "./tab-content-ar/rageQuitTab";
import SaveSnafuTab from "./tab-content-ar/saveSnafuTab";
import LootLossTab from "./tab-content-ar/lootLoss";
import OtherBlundersTab from "./tab-content-ar/otherBlunders";

const HomeTabs = () => {
  return (
    <>
      <div className="text-center nav-tabs-header es">
        <h2 className="text-uppercase">التخبيصات إلّي نغطيها:</h2>
        <TabLinks />
      </div>
      <div className="tab-content text-center text-white" id="myTabContent">
        <NoobMoveTab />
        <EarlyDeathTab />
        <RageQuitTab />
        <SaveSnafuTab />
        <LootLossTab />
        <OtherBlundersTab />
      </div>
    </>
  );
};

export default HomeTabs;
